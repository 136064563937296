td {
  padding: 18px 41px;
  text-align: left;
  white-space: nowrap !important;
  font-size: 15px;
}



.td-tile {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height */

  letter-spacing: 0.02em;

  color: #000000;
}

th {
  padding: 18px 41px;
  line-height: 20px;
  white-space: nowrap !important;
  text-align: left !important;

  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height */

  letter-spacing: 0.02em;

  color: #8290a0;
}

@media screen and (max-width: 960px) {
  th {
    padding: 18px 9px;
    line-height: 20px;
    white-space: nowrap !important;
    text-align: left !important;

    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height */

    letter-spacing: 0.02em;

    color: #8290a0;
  }

  td {
    padding: 18px 9px;
    text-align: left;
    white-space: nowrap !important;
    font-size: 15px;
  }
}

@media screen and (max-width: 590px) {
  table {
    overflow-x: auto;
  }
}
