.button {
  cursor: pointer;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  padding: 0px 40px;
  border-radius: 10px;
  height: 41px;
  outline: none;
  border: transparent;
}

.disabled-button {
  width: 100%;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  background: #d3d3d3;
  border-radius: 3px;
  height: 41px;
}

.small {
  padding: 10px 20px;
  font-weight: 400;
}

.orange {
  background: #fcb45d;
  color: #000000;
}

.dark {
  background: #000000;
  color: #fff;
}
.green {
  background: #00a859;
  color: #fff;
}
.plain-orange {
  color: #ec7e3d;
  border: 1px solid #ec7e3d;
}
.plain-green {
  color: #00a859;
  border: 1px solid #00a859;
  background-color: #fff;
}
.cancel {
  color: #fff;
  background-color: #f44336;
  border: 1px solid #f44336;
}
