.dashboard {
  overflow-x: hidden;
  height: 100vh;
}

.bg-line-side {
  border-right: 1px solid #e5e7eb;
}

.show-web{
  display: block;
}
.nav-link-menu {
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 120%;
  /* or 19px */

  /* cool-gray/900 */

  color: #111827;
}


.pg-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
  display: flex;
  align-items: center;

  color: #000000;
}
.navbar-new {
  padding: 0rem !important;
  position: fixed !important;
  top: 0 !important;
  margin: 25px 20px 25px 0px;
}

.dash-logo {
  height: 26px;
}
.nav-hover {
  color: #b4b4bb;
  line-height: 21px;
  padding-bottom: 0.3rem;
  padding-top: 0.3rem;
  margin-bottom: 0.5rem;
  width: 240px;
}

.set-part{
  margin: 8rem 0; 
  border-top: 1px solid #e5e7eb;
  padding-top: 5px;
  
}
.brand {
  margin-left: 1.5rem;
}
/* .nav-hover:hover,
.active {
  background: #f3fff6;
  color: #F3F4F6 !important;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
} */
/* .nav-hover:hover path {
  fill: #F3F4F6 !important;
} */
.active path {
  fill: #F3F4F6 !important;
}
.active .not-fill path {
  fill: #fff !important;
}
.side-width {
  width: 221px;
}
.top-top {
  padding-top: 3.1rem;
}
.top-2 {
  padding-top: 7.3em;
  padding-left: 35px;
  padding-right: 45px;
  /* height: 850px; */
}
.col-pd {
  padding: 0rem !important;
  background-color: #f8f8f8;
}
.col-pd-2 {
  padding: 0rem !important;
}
.top-main {
  position: fixed !important;
  width: 100% !important;
  top: 0 !important;
  right: 0;
  left: 0;
  z-index: 2;
  padding-left: 222px;
}

.top-main-sub {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding-left: 35px;
  height: 80px;
}

.top-main .title {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}
.notif {
  margin-top: -20px;
}
.top-line {
  height: 85px;
  border-right: 1px solid #e1e1e3;
}
.profile-name {
  font-size: 14px;
  line-height: 21px;
}
.profile-drop-text {
  font-size: 14px;
}
.no-deco {
  text-decoration: none !important;
}
.notif-drop {
  width: 377px !important;
  max-height: 300px !important;
  margin-top: 40px !important;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.04);
  border-radius: 5px;
  overflow-y: auto;
}
.nothing-here {
  margin-top: 50px;
}
.chat-height {
  font-size: 12px;
}

.user-profile-pic {
  border-radius: 50%;
  width: 32px;
  height: 32px;
}
.show-on-mobile {
  display: none;
}
.icon-sm {
  width: 23px !important;
}
.drop-menu {
  position: relative !important;
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 0px !important;
}

.show-mobile{
  display: none !important;
}

@media screen and (max-width: 960px) {
  .dashboard {
    height: 100%;
  }
  .show-mobile{
    display: block !important;
  }
  .top-main {
    display: none;
  }
  .nav-hover {
    width: 100%;
  }
  .side-width {
    width: auto;
  }
  .show-web{
    display: none !important;
  }
  .brand {
    margin-left: 0rem !important;
  }
  .top-2 {
    padding-top: 6.5em;
    padding-left: 25px;
    padding-right: 25px;
  }
  .navbar-new {
    z-index: 1000;
    background-color: #fff;
    width: 100%;
    margin: 0px;
    padding: 1em !important;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .show-on-mobile {
    display: block;
  }
}
