.choose-border {
  background: #f9f9fa;
  border-radius: 10px;
  padding: 30px 20px;
}

.choose-border-active {
  background: #f9f9fa;
  border: 1px dotted red;
  border-radius: 10px;
  padding: 30px 20px;
}

.copy-wallet {
  height: 25px;
  left: 21px;
  text-align: center;
  width: 40%;
  top: 6px;
  background: #0d0d0d;
  border-radius: 10px;
  color: white;
}

.select-card {
  background: white;
  border: 1px solid #d0d5dd;
  padding: 12px 10px;
  border-radius: 10px;
  margin-top: -0.6rem;
  color: A3A9B5;
  font-weight: 300;
}

.upload-ch {
  background: #f9f9fa;
  border-radius: 10px;
  padding: 30px 20px;
}
